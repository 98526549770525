import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import { FaPlus, FaListOl, FaChartLine, FaUsers } from "react-icons/fa";
import BenefitBlock from "../components/benefitBlock";
import Lightbox from "../components/lightBox";
import SEO from "../components/seo";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";

export const query = graphql`
  query {
    szanse: file(
      relativePath: { eq: "prosty-crm-szanse-sprzedazy-quickcrm.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    sprzedaz: file(
      relativePath: { eq: "Sprzedaz-w-prostym-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    szansewcrm: file(
      relativePath: { eq: "Szansa-sprzedazy-w-prostym-systemie-CRM.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    raporty1: file(
      relativePath: {
        eq: "Raporty-w-prostym-systemie-CRM-aktywnosc-handlowcow.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    raporty2: file(
      relativePath: { eq: "Raporty-w-prostym-CRM-lejek-szans-sprzedazy.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    raporty3: file(
      relativePath: {
        eq: "Raporty-w-prostym-systemie-CRM-przyczyny-utraty.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1349, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const SprzedazWCrm = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Uporządkowany proces sprzedaży"
      headline="Twórz własne kampanie i efektywnie zarządzaj szansami sprzedaży"
    >
      <SEO
        title="Sprzedaż w CRM - zarządzaj szansami sprzedaży"
        description="Sprzedaż w CRM • Widok szans sprzedaży - kampanie i analiza sprzedaży • Wszystkie informacje w CRM • Wypróbuj QuickCRM przez 30 dni za darmo!"
        keywords={["sprzedaż w crm"]}
      />
      <p>
        <strong>
          Jednym z&nbsp;najważniejszych zadań systemu CRM jest uporządkowanie
          procesu sprzedaży. System sprawia, że jest on bardziej efektywny
          i&nbsp;wydajny.
        </strong>{" "}
        <strong>
          Wykorzystasz do tego funkcję zarządzania szansami sprzedaży
        </strong>
        , która w&nbsp;graficzny sposób prezentuje całą kampanię sprzedażową.
        Takie zestawienie pozwala zebrać w&nbsp;jednym miejscu najważniejsze
        informacje na temat całej sprzedaży. To skuteczne narzędzie pracy dla
        handlowców, ponieważ pomaga im zrealizować zaplanowane cele. Jest także
        niezwykle przydatne dla menedżera, który musi stale monitorować proces
        sprzedaży.
      </p>
      <p>
        <strong>
          Tak zaprojektowany proces prowadzi handlowca po przemyślanej ścieżce
          sprzedażowej, a&nbsp;szefowi sprzedaży pozwala w&nbsp;szybki sposób
          monitorować sytuację i&nbsp;wprowadzać usprawnienia.
        </strong>
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto"
        }}
        images={[data.szanse]}
        alts={["sprzedaż w crm"]}
      />
      <p
        style={{
          textAlign: "center"
        }}
      >
        Dostosuj wygląd kampanii do własnego procesu sprzedaży.
      </p>
                        <br />
            <br />
      <br />
      <br />
      <h3>
        <strong>Jak to działa?</strong>
      </h3>
      <p>
        <strong>W kolumnach zaprezentowane są</strong>{" "}
        <strong>
          etapy procesu sprzedaży wraz z&nbsp;podsumowaniem liczby
          i&nbsp;prognozowanej wartości szans na każdym etapie
        </strong>
        . W&nbsp;każdej z&nbsp;kolumn znajdują się szanse sprzedaży, a&nbsp;ich
        ikony informują o&nbsp;zaplanowanych, jeszcze nie zaplanowanych
        i&nbsp;niezrealizowanych zadaniach. Widok posiada wiele filtrów (np.
        według osoby odpowiedzialnej, terminu, zadań na dziś), tak abyś mógł
        jeszcze szybciej odszukać potrzebne informacje i&nbsp;łatwiej planować
        zadania.&nbsp;
        <strong>
          Natomiast w&nbsp;samej szansie znajdują się posegregowane informacje
          na temat planowanej sprzedaży dla danego klienta, a&nbsp;także
          o&nbsp;wykonanych przez Twoich handlowców zadaniach względem danej
          szansy.
        </strong>
      </p>
      <em>
        Możesz mieć wiele aktywnych kampanii jednocześnie np. proces
        wprowadzania produktu na rynek i proces rozwijania współpracy z obecnymi
        klientami.
      </em>
      <Lightbox
        images={[data.sprzedaz, data.szansewcrm]}
        alts={["sprzedaż w crm", "szanse sprzedaży - sprzedaż w crm"]}
      />
                        <br />
                        <br />
      <h3>
        <strong>Raporty</strong>
      </h3>
      <p>
        <strong>
          Zestawienia, które pokocha każdy analityk, kierownik czy szef
          sprzedaży.
        </strong>{" "}
        Nikt nie chce poświęcać wielu godzin na ich tworzenie, prawda?
      </p>
      <p>
        CRM na podstawie danych, zebranych z&nbsp;całej kampanii, tworzy zawsze
        aktualne raporty, które dostarczają takich informacji jak np.:
      </p>
      <p>
      <ul>
        <li>
          <strong>liczba i&nbsp;wartość wygranych szans sprzedaży</strong>,
          które pozwolą monitorować sprzedaż,
        </li>
        <li>
          <strong>konwersję szans sprzedaży</strong> na każdym etapie, dzięki
          której wskażesz wąskie gardła Twojego procesu,
        </li>
        <li>
          <strong>przyczyny utraty szans sprzedaży</strong>, dzięki którym
          udoskonalisz swoją ofertę,
        </li>
        <li>
          <strong>aktywność handlowców</strong>, na podstawie której opracujesz
          najlepszy sposób pracy z&nbsp;klientem.
        </li>
      </ul>
      </p>
      <br />
      <Lightbox 
      images={[data.raporty1, data.raporty2, data.raporty3]}
      alts={["raporty - sprzedaż w crm, raportowanie - sprzedaż w crm, sprzedaż w crm - raporty"]}
       />
      <h3
        style={{
          textAlign: "center"
        }}
      >
        <strong>Co zyskujesz?</strong>
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaPlus size="45px" />}
          content="Uporządkowany proces sprzedaży"
        />
        <BenefitBlock
          icon={<FaListOl size="45px" />}
          content="Możliwość szybkiej weryfikacji działań handlowców"
        />
        <BenefitBlock
          icon={<FaChartLine size="45px" />}
          content="Zawsze aktualne raporty na temat sprzedaży"
        />
        <BenefitBlock
          icon={<FaUsers size="45px" />}
          content="Skuteczne narzędzie pracy dla handlowca"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default SprzedazWCrm;
